import React from 'react';
import { Link } from 'gatsby';
import WaitingList from './WaitingList';

// import github from '../img/github-icon.svg'
import logo from '../img/logo.svg';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      }
    );
  };

  render() {
    return (
      <nav className="navbar is-primary" role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="CPEU.org" aria-label="CPEU.org">
              <img src={logo} alt="CPEU.org" />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              aria-label="Hamburger Menu"
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              onKeyPress={() => this.toggleHamburger()}
              role="button"
              tabIndex={0}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-start has-text-centered">
              {/* <Link className="navbar-item" to="/products">
                Products
              </Link> */}
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link ">Features</div>

                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/features/search">
                    Search
                  </Link>
                  <Link className="navbar-item" to="/features/filter">
                    Filter
                  </Link>
                  <Link className="navbar-item" to="/features/bookmark">
                    Bookmark
                  </Link>
                  <Link className="navbar-item" to="/features/transfer">
                    Transfer
                  </Link>
                </div>
              </div>
              <Link className="navbar-item" to="/pricing">
                Pricing
              </Link>
              <Link className="navbar-item" to="/about">
                About
              </Link>
              <Link className="navbar-item" to="/blog">
                Blog
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <Link to="/signup" className="button is-primary btn" aria-label="Sign Up">
                    Sign up
                  </Link>
                  <Link to="/login" className="button is-light btn" aria-label="Log In">
                    Log in
                  </Link>
                  {/* <WaitingList light location={'Navbar'} url={''} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
