import React from 'react';
import { Link } from 'gatsby';

import logo from '../img/logo-green.svg';
import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';
// import twitter from '../img/social/twitter.svg';
// import vimeo from '../img/social/vimeo.svg';

function Footer() {
  return (
    <footer className="footer has-background-black has-text-white-ter">
      <div className="content has-text-centered">
        {/* CPEU.org */}
        <img
          src={logo}
          alt="CPEU.org"
          style={{ width: '240px' }}
          // style={{ width: '14em', height: '10em' }}
        />
      </div>
      <div className="content has-text-centered has-background-black has-text-white-ter">
        <div className="container has-background-black has-text-white-ter">
          <div className="columns">
            <div className="column is-4">
              <section className="menu">
                <ul className="menu-list" style={{ margin: '2em' }}>
                  <li>
                    <Link to="/" className="navbar-item">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/pricing">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </section>
            </div>
            <div className="column is-4">
              <section>
                <ul className="menu-list" style={{ margin: '2em' }}>
                  <li>
                    <div className="has-text-weight-semibold menu-label" style={{ padding: '8px' }}>
                      Features:
                    </div>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/features/search">
                      Search
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/features/filter">
                      Filter
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/features/bookmark">
                      Bookmark
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/features/transfer">
                      Transfer
                    </Link>
                  </li>
                </ul>
              </section>
            </div>
            <div className="column is-4">
              <section>
                <ul className="menu-list" style={{ margin: '2em' }}>
                  <li>
                    <Link to="/privacy" className="navbar-item">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/terms">
                      Terms and Conditions
                    </Link>
                  </li>
                  {/* <li>Copyright 2020 CPEU.org</li> */}
                  <li style={{ display: 'inline-flex' }}>
                    <a
                      title="instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://instagram.com/CPEUorg/"
                    >
                      <img
                        src={instagram}
                        alt="CPEUorg | Instagram"
                        style={{ width: '1.5em', height: '1.5em' }}
                      />
                    </a>
                    <a
                      title="facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://facebook.com/CPEUorg/"
                    >
                      <img
                        src={facebook}
                        alt="CPEUorg | Facebook"
                        style={{ width: '1.5em', height: '1.5em' }}
                      />
                    </a>
                  </li>
                </ul>
              </section>

              {/* <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a> */}
            </div>
          </div>
          <div style={{ margin: '16px, 0, 0, 0' }}>
            <em>Copyright ©2020 CPEU.org. All rights reserved.</em>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
