import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';
// import { Helmet } from 'react-helmet';
// import { withPrefix } from 'gatsby';
import SEO from './SEO';
import Footer from './Footer';
import Navbar from './Navbar';
import './all.sass';
// import useSiteMetadata from './SiteMetadata';

const TemplateWrapper = ({ children, customSEO }) => {
  // const { title, description, siteUrl } = useSiteMetadata();
  return (
    <ParallaxProvider>

      <div>
        {/* {console.log('TemplateWrapper children customSEO', children, customSEO)} */}
        {!customSEO && (
          <SEO
            title={children.props ? children.props.title : null}
            banner={children.props.image ? children.props.image.childImageSharp.fluid.src : null}
          />
        )}
        {/* <Helmet>
          {/* <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} /> 

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix('/')}img/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-96x96.png`}
            sizes="96x96"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-32x32.png`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-16x16.png`}
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />

          {/* <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content={siteUrl} />
          <meta property="og:image" content={`${withPrefix('/')}img/og-image.jpg`} /> 
        </Helmet> */}
        <Navbar />
        <div>{children}</div>
        <Footer />
      </div>
    </ParallaxProvider>
  );
};

export default TemplateWrapper;

// Layout.propTypes = {
//   children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
// }

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  customSEO: PropTypes.bool,
};
TemplateWrapper.defaultProps = {
  customSEO: false,
};
